<template
  ><div style="padding-top:-2px;margin-top:-2px;">
    <v-label
      ><b>{{ label }}</b></v-label
    >
    <s-select-generic
      class="text"
      itemvalue="AtaID"
      :disabled="disabled"
      :value="value"
      id="AtaID" itemtext="AtaDescription"
      :return-object="returnObject"
      @input="input($event)"
      @select="select($event)"
      :config="config"
      :full="full"
      :text="text"
      :clearable="clearable"
      :noDefault="clearable"
    >
    </s-select-generic>
  </div>
</template>
<script>

import _sService from "@/services/FreshProduction/PrfActivityAreaService.js";

export default {
  components: {},
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    value: null,
    text: { type: String, default: "" },
    label: {
      type: String,
      default: "",
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    typeArea : {
      type: Number,
      default: 0,
    },
    typeShip:{
      type: Number,
      default: 1, //default frescos
    },
    noDefault: {
      type: Boolean,
      default: false,
    },    
    full: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value(value) {
      this.item = value;
    },
      typeArea(){         
         this.config.params.TypeArea=this.typeArea;
            this.config = {
                url: _sService.GetUrllist(),
                title: this.label,
                params: { requestID: this.$fun.getUserID(),  TypeShip: this.typeShip, Typearea: this.typeArea },
            };
    },
    typeShip(){         
         this.config.params.TypeShip=this.typeShip;
    }
  },
  methods: {
    input(val) {
      this.$emit("input", val);
    },
     select(val) {
      this.$emit("select", val);
    },
  },
  data: () => ({
    item: 0,
    val: "",
    items: [],
    config: {},
  }),

  created() {
      this.config = {
        url: _sService.GetUrllist(),
        title: this.label,
        params: { requestID: this.$fun.getUserID(), TypeShip: this.typeShip, Typearea: this.typeArea },
    };
  },
};
</script>
