<template>
  <v-container>
    <div>
      <s-crud
        :filter="filter"
        :config="config"
        edit
        :enable="selected.RcfState >= 4 ? true : false"
        @enable="enableLP()"
        @save="save($event)"
        @EditEvent="editEvent()"
        height="auto"
        @rowSelected="rowSelected($event)"
        search-input
        ref="gridReceptionFresh"
        :title="title"
        no-full
        :sync="$fun.hasSpecialPermission('syncLot')"
        @sync="syncLot()"
        >

        <template v-slot:filter>
          <v-container>
            <v-row style="margin-left: 5px" justify="center">
              <v-col cols="6" md="6" lg="3" class="pb-0 pt-1">
                <s-date
                  label="Fecha Inicio"
                  v-model="filter.cDateInitial"
                ></s-date>
              </v-col>
              <v-col cols="6" md="6" lg="3" class="pb-0 pt-1">
                <s-date label="Fecha Fin" v-model="filter.cDateFin"></s-date>
              </v-col>

              <v-col
                cols="6"
                md="6"
                lg="3"
                v-if="filter.filterAll == 0"
                class="pb-0 pt-1"
              >
                <s-select-definition
                  clearable
                  :def="1172"
                  label="Tipo Cultivo"
                  v-model="filter.TypeCrop"
                />
              </v-col>
              <v-col
                cols="6"
                md="6"
                lg="3"
                v-if="filter.filterAll == 0"
                class="pb-0 pt-1"
              >
                <s-select-definition
                  clearable
                  :def="1173"
                  label="Cultivo"
                  v-model="filter.TypeCultive"
                />
              </v-col>

              <v-col
                cols="6"
                md="6"
                lg="3"
                v-if="filter.filterAll == 0"
                class="pb-0 pt-1"
              >
                <s-select-variety
                  clearable
                  :cultiveID="filter.TypeCultive"
                  label="Variedad"
                  full
                  v-model="filter.VrtID"
                />
              </v-col>

              <v-col
                cols="6"
                md="6"
                lg="3"
                v-if="filter.filterAll == 0"
                class="pb-0 pt-1"
              >
                <s-select-definition
                  clearable
                  label="Tipo de Servicio"
                  :def="1171"
                  v-model="filter.cTypeServiceID"
                ></s-select-definition>
              </v-col>

              <v-col
                cols="6"
                md="6"
                lg="3"
                v-if="filter.filterAll == 0"
                class="pb-0 pt-1"
              >
                <s-select-type-certification
                  clearable
                  label="Certificacion"
                  full
                  v-model="filter.TcrID"
                ></s-select-type-certification>
              </v-col>

              <v-col
                cols="6"
                md="6"
                lg="3"
                v-if="filter.filterAll == 0"
                class="pb-0 pt-1"
              >
                <s-select-definition
                  :def="1230"
                  label="Planta Procesadora"
                  v-model="filter.TypeProcessingPlant"
                  clearable
                />
              </v-col>
            </v-row>
            <v-row style="margin-left: 5px">
              <v-col cols="12" lg="3" md="3" class="pt-1">
                <s-select-definition
                  clearable
                  label="Estado"
                  :def="1256"
                  v-model="filter.TypeState"
                ></s-select-definition>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="6" lg="2" class="mt-3 pb-0">
                <v-btn
                  style="color: white"
                  small
                  color="info"
                  rounded
                  block
                  @click="WeighMaterial()"
                >
                  Pesado
                </v-btn>
              </v-col>
              <v-col cols="6" lg="2" class="mt-3 pb-0">
                <v-btn
                  @click="ModalBrix()"
                  style="color: white"
                  small
                  rounded
                  block
                  color="success"
                >
                  Muestreo
                </v-btn>
              </v-col>

              <!-- <v-col cols="6" sm="2" v-if="ship == 2">
								<v-btn 
									@click="dialogDecrease = true"
									style="color: white"
									small
									rounded
									block
									color="warning"
								>
									Merma
								</v-btn>
							</v-col> -->
            </v-row>
          </v-container>
        </template>

        <template v-slot:ViewCert="{ row }">
          <v-btn x-small color="info" block outlined @click="clickView(row)">
            <v-icon style="font-size: 16px !important">fas fa-eye</v-icon>
          </v-btn>
        </template>

        <template scope="props">
          <alert-supplies :FltID="objLot.FltID"></alert-supplies>

          <!-- <v-btn
						@click="openAudio()"
						:color="props.item.TypeStateColor"
						v-if="disabledAll == true"
						style="
							transform: rotate(339deg);
							z-index: 9999;
							left: 0%;
							top: 30%;
							width: inherit;
							opacity: 80%;
							"
						>{{ props.item.TypeStateName }}
					</v-btn> -->
          <!-- <i
            v-if="disabledAll == true"
            class="fa fa-fingerprint"
            style="
              font-size: 265px;
              cursor: pointer;
              position: fixed;
              left: 40%;
              z-index: 99999;
              color: #8e8f91;
            "
            @click="openAudio()"
          ></i> -->

          <v-card :disabled="disabledAll">
            <!-- Inicio Datos Recepcion Vigilancia -->
            <v-row>
              <div class="col-md-12 col-sm-12">
                <v-col cols="12" md="12">
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-toolbar
                        dense
                        dark
                        :color="'#BAB6B5'"
                        style=" height: 28px; -webkit-box-shadow: 2px 1px 0px 0px rgb(0 0 0 / 10%), 0px 0px 0px 0px rgb(0 0 0 / 4%), 0px 1px 0px 0px rgb(0 0 0 / 2%); border-radius: 5px; col-md-6;"
                      >
                        <v-toolbar-title style="height: 38px"
                          >Datos Recepcion Vigilancia</v-toolbar-title
                        >
                      </v-toolbar>
                    </v-col>
                    <v-col cols="12" md="6">
                      <p>
                        Fecha Recepcion:
                        {{ props.item.RcfDateReceptionSurveillance }}
                      </p>
                    </v-col>
                    <v-col cols="6" md="3">
                      <p>Planta Procesadora:</p>
                    </v-col>
                    <v-col cols="6" md="3">
                      <s-select-definition
                        :def="1230"
                        v-model="props.item.TypeProcessingPlant"
                      />
                    </v-col>
                    <v-col cols="12" md="6" style="margin-top: -35px">
                      <p>
                        Nro. Recepcion:
                        {{ props.item.RcsID }}
                      </p>
                    </v-col>
                    <v-col
                      cols="6"
                      md="3"
                      style="margin-top: -30px"
                      v-if="props.item.TypeProcessingPlant == 2"
                    >
                      <p>Empacadora:</p>
                    </v-col>
                    <v-col
                      cols="6"
                      md="3"
                      style="margin-top: -30px"
                      v-if="props.item.TypeProcessingPlant == 2"
                    >
                      <s-select-definition
                        :def="1226"
                        v-model="props.item.TypeBaler"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </div>
            </v-row>
            <!-- Fin Datos Recepcion Vigilancia -->

            <!-- Inicio Fundo || Vehiculos  -->
            <v-row style="margin-top: -30px; margin-bottom: -40px">
              <!-- Inicio Fundo/User -->
              <div class="col-lg-12 col-md-12">
                <v-card outlined rounded elevation="2" dense>
                  <v-card-text>
                    <b>Datos de Fundo - </b>
                    <b>{{ objLot.FltCodSenasa }}</b>
                  </v-card-text>
                  <v-card-actions>
                    <v-row>
                      <v-col lg="2" md="2" cols="12">
                        <s-search-lot-by-cod-senasa
                          @searchSenasa="searchSenasa($event)"
                        />
                      </v-col>

                      <v-col cols="12" lg="4" md="4">
                        <s-text
                          label="Productor"
                          v-model="objLot.PrdText"
                          readonly
                        ></s-text>
                      </v-col>

                      <v-col cols="12" lg="4" md="4">
                        <s-text
                          readonly
                          label="Fundo"
                          v-model="objLot.FagName"
                        ></s-text>
                      </v-col>

                      <v-col cols="12" lg="2" md="2">
                        <s-text
                          readonly
                          label="Lote"
                          v-model="objLot.FltName"
                        ></s-text>
                      </v-col>

                      <v-col cols="6" md="2" style="margin-top: -15px">
                        <s-select-definition
                          label="Tipo de Servicio"
                          :def="1171"
                          v-model="props.item.TypeService"
                        />
                      </v-col>

                      <v-col cols="6" md="2" style="margin-top: -15px">
                        <s-select-definition
                          :def="1174"
                          label="Procedencia"
                          v-model="props.item.TypeOrigin"
                        />
                      </v-col>

                      <v-col cols="6" md="2" style="margin-top: -15px">
                        <s-select-definition
                          :def="1172"
                          label="Tipo Cultivo"
                          v-model="objLot.TypeCrop"
                        />
                      </v-col>
                      <v-col cols="6" md="2" style="margin-top: -15px">
                        <s-select-definition
                          :def="1173"
                          label="Cultivo"
                          v-model="objLot.TypeCultive"
                          readonly
                        />
                      </v-col>

                      <v-col cols="6" md="4" style="margin-top: -15px">
                        <s-select-variety
                          :cultiveID="objLot.TypeCultive"
                          label="Variedad"
                          full
                          :text="props.item.VrtName"
                          :value="objLot.VrtID"
                          v-model="objLot.VrtID"
                          readonly
                        />
                      </v-col>

                      <v-col cols="12" md="4" style="margin-top: -15px">
                        <v-text-field
                          label="Direccion: "
                          v-model="objLot.FagAddress"
                          readonly
                        ></v-text-field>
                      </v-col>

                      <v-col cols="6" md="4" style="margin-top: -15px">
                        <v-text-field
                          label="Zona: "
                          v-model="objLot.ZonIDText"
                          readonly
                        ></v-text-field>
                      </v-col>

                      <v-col cols="6" md="4" style="margin-top: -15px">
                        <v-text-field
                          label="Sub Zona: "
                          v-model="objLot.SzoIDText"
                          readonly
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </v-card>
              </div>
              <!-- Fin Fundo/User -->

              <!-- Inicio Vehicle -->
              <div class="col-lg-12 col-md-12" style="margin-top: -20px">
                <v-col cols="12" md="12">
                  <v-card outlined rounded elevation="2">
                    <v-card-text>
                      <b>Datos de Vehiculo</b>
                    </v-card-text>
                    <!-- <v-card-actions> -->
                    <v-row>
                      <v-col cols="12" md="12" style="margin-top: 0px">
                        <v-data-table
                          item-key="RcsTptID"
                          dense
                          :items="itemsVehicle"
                          :headers="headersVehicle"
                          v-model="selectedVehicle"
                          :items-per-page="-1"
                          @click:row="rowClickVehicle"
                          hide-default-footer
                          disable-sort
                        >
                          <template v-slot:item.TptEnrollment="props">
                            <s-select-transport
                              :value="props.item.TptEnrollment"
                              full
                              v-model="props.item.TptEnrollment"
                            >
                            </s-select-transport>
                          </template>
                          <template v-slot:item.TptDriver="props">
                            <s-select-driver
                              clearable
                              full
                              :value="props.item.TptDriver"
                              v-model="props.item.TptDriver"
                            >
                            </s-select-driver>
                          </template>

                          <template v-slot:item.TypeLoadName="props">
                            <s-select-definition
                              :def="1229"
                              v-model="props.item.TypeLoad"
                            />
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                    <!-- </v-card-actions> -->
                  </v-card>
                </v-col>
              </div>
              <!-- Fin Vehicle -->
            </v-row>

            <!-- Fin Fundo || Vehiculos  -->

            <!-- Inicio Capataz -->
            <v-row>
              <div class="col-lg-12 col-md-12">
                <v-col cols="12" md="12">
                  <v-card outlined rounded elevation="2">
                    <v-card-text>
                      <b>Datos de Capataz</b>
                    </v-card-text>

                    <!-- <v-card-actions> -->
                    <!-- <v-row style="margin:auto">
											
										</v-row> -->
                    <v-row>
                      <!-- <v-col
												cols="6"
												v-if="selectedGang.length > 0"
											>
												<s-select-transport
													clearable
													full
													v-model="
														selectedGang[0].VehPlate
													"
													label="Placa"
												>
												</s-select-transport>
											</v-col>
											<v-col
												cols="6"
												v-if="selectedGang.length > 0"
											>
												<s-select-driver
													label="Chofer"
													clearable
													full
													v-model="
														selectedGang[0]
															.DriverName
													"
												/>
											</v-col> -->
                      <v-col cols="12">
                        <v-data-table
                          item-key="RcsGngID"
                          dense
                          :items="itemsGang"
                          :headers="headersGang"
                          v-model="selectedGang"
                          :items-per-page="-1"
                          @click:row="rowClickGang"
                          hide-default-footer
                          disable-sort
                        >
                          <template v-slot:item.FrmQuantityReal="props">
                            <s-text
                              v-model="props.item.FrmQuantityReal"
                              number
                            ></s-text>
                          </template>

                          <template v-slot:item.FrmQuantityHarvester="props">
                            <s-text
                              v-model="props.item.FrmQuantityHarvester"
                              number
                            ></s-text>
                          </template>
                           <template v-slot:item.TypeChargeName="props">
                            <s-text
                              v-model="props.item.TypeChargeName"
                               readonly 
                            ></s-text>
                          </template>
<!-- :value="props.item.FrmID" -->
                          <template v-slot:item.FrmName="props">
                            <s-select-foreman
                              clearable
                              full
                              
                              v-model="props.item.FrmID"
                            ></s-select-foreman>
                          </template>

                          <template v-slot:item.FltName="props">
                            <s-select-lot
                              v-if="objLot.FltID"
                              :fkFltID="objLot.FltID"
                              :PrdID="objLot.PrdID"
                              v-model="props.item.FltID"
                              clearable
                              full
                            />
                          </template> 
                          </v-data-table
                      ></v-col>
                    </v-row>
                    <!-- </v-card-actions> -->
                  </v-card>
                </v-col>
              </div>
            </v-row>
            <!-- Fin Capataz -->

            <!-- Inicio Documentos || Datos Lote Recepcion  -->
            <v-row style="margin: auto">
              <!--Inicio datos Lote -->
              <v-col cols="12" lg="4" md="4">
                <v-card flat outlined>
                  <v-card-text>
                    <b>Lote de Recepcion</b>
                  </v-card-text>

                  <v-card-actions>
                    <v-row>
                      <v-col cols="12" md="12" style="margin-top: 0px">
                        <s-select-definition
                          clearable
                          label="Zona Ubicacion"
                          v-model="props.item.TypeZonLocation"
                          :def="1253"
                        />
                      </v-col>
                      <!-- <v-col cols="6" md="6" style="margin-top: 0px">
												<s-select-definition
													clearable
													label="Calidad"
													v-model="
														props.item.TypeQualityExportation
													"
													:def="1254"
												/>
											</v-col> -->
                      <v-col cols="6" md="6" style="margin-top: -10px">
                        <s-text
                          label="Peso Recepción"
                          v-model="props.item.WbbNetWeight"
                          decimal
                          readonly
                        />
                      </v-col>

                      <v-col cols="6" md="6" style="margin-top: -10px">
                        <s-select-definition
                          clearable
                          label="Alerta"
                          v-model="props.item.TypeAlert"
                          :def="1312"
                        />
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </v-card>
              </v-col>
              <!--Fin datos Lote -->

              <!--Inicio Documentos -->
              <v-col cols="12" lg="8" md="8">
                <v-card flat outlined>
                  <v-card-text>
                    <b>Documentos || </b>
                    <span
                      style="
                        margin-left: 2%;
                        color: red;
                        font-size: 11px;
                        position: absolute;
                      "
                    >
                      <b>Campos Obligatorios (*)</b>
                    </span>
                  </v-card-text>

                  <v-card-actions>
                    <v-row>
                      <v-col
                        cols="6"
                        :md="selected.FrzID == 0 ? 3 : 6"
                        style="margin-top: 0px"
                      >
                        <s-text
                          :label="
                            selected.FrzID == 0
                              ? 'Nro. Guia de Cosecha'
                              : 'N° Guia interna'
                          "
                          type="text"
                          v-model="props.item.RcfNumberHarvestGuide"
                          :mask="maskFormatGuideHarvested"
                        >
                          <template v-slot:append>
                            <i
                              style="color: red; font-size: 9px"
                              class="fa fa-asterisk"
                            ></i>
                          </template>
                        </s-text>
                      </v-col>

                      <v-col
                        cols="6"
                        md="3"
                        style="margin-top: 0px"
                        v-if="selected.FrzID == 0"
                      >
                        <s-text
                          label="Nro. Guia de Remision"
                          type="text"
                          v-model="props.item.RcfNumberReferralGuide"
                          :mask="maskFormatGuideRemission"
                        >
                          <template v-slot:append>
                            <i
                              style="color: red; font-size: 9px"
                              class="fa fa-asterisk"
                            ></i>
                          </template>
                        </s-text>
                      </v-col>

                      <v-col
                        cols="6"
                        md="3"
                        style="margin-top: 0px"
                        v-if="selected.FrzID == 0"
                      >
                        <s-text
                          label="Precinto Sunshine"
                          type="text"
                          v-model="props.item.RcfSunshineSeal"
                        >
                          <template v-slot:append>
                            <i
                              style="color: red; font-size: 9px"
                              class="fa fa-asterisk"
                            ></i>
                          </template>
                        </s-text>
                      </v-col>

                      <v-col
                        cols="6"
                        md="3"
                        style="margin-top: 0px"
                        v-if="selected.FrzID == 0"
                      >
                        <s-text
                          label="Precinto Senasa"
                          type="text"
                          v-model="props.item.RcfSenasaSeal"
                        />
                      </v-col>

                      <v-col
                        cols="6"
                        md="3"
                        style="margin-top: -10px"
                        v-if="selected.FrzID == 0"
                      >
                      <!-- :mask="'###-###############'" -->
                        <s-text
                          label="N.G.R Transportista"
                          type="text"
                          v-model="props.item.RcfNumberFerralGuideDriver"
                          
                        >
                          <template v-slot:append>
                            <i
                              style="color: red; font-size: 9px"
                              class="fa fa-asterisk"
                            ></i>
                          </template>
                        </s-text>
                      </v-col>

                      <v-col
                        cols="6"
                        md="3"
                        style="margin-top: -10px"
                        v-if="selected.FrzID == 0"
                      >
                        <s-text
                          label="N.G.R-Materiales"
                          type="text"
                          v-model="props.item.RcfNumberFerralGuideMaterials"
                          :mask="'###-###############'"
                        />
                      </v-col>

                      <v-col
                        cols="6"
                        md="3"
                        style="margin-top: -10px"
                        v-if="selected.FrzID == 0"
                      >
                        <s-text
                          label="N.G.R Trans-Materiales"
                          type="text"
                          v-model="
                            props.item.RcfNumberFerralGuideDriverMaterials
                          "
                          
                        />
                      </v-col>
<!-- :mask="'###-###############'" -->
                      <v-col
                        cols="6"
                        :md="selected.FrzID == 0 ? 3 : 6"
                        :style="selected.FrzID == 0 ? 'margin-top: -10px' : ''"
                      >
                        <s-text
                          label="Peso Guia"
                          decimal
                          v-model="props.item.RcfWeightGuide"
                          :readonly="selected.FrzID > 0 ? true : false"
                        >
                        </s-text>
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </v-card>
              </v-col>
              <!--Fin Documentos -->
            </v-row>

            <v-row>
              <div class="col-md-12 col-lg-12" cols="12">
                <v-col cols="12" md="12" style="margin-top: -20px">
                  <s-textarea
                    style="margin-top: -15px"
                    v-model="props.item.RcfObservations"
                    label="Observaciones"
                  ></s-textarea>
                </v-col>
              </div>
            </v-row>
            <!-- Fin Documentos || Datos Lote Recepcion -->
          </v-card>
        </template>

        <template v-slot:RcfState="{ row }">
          <v-chip x-small :color="row.TypeStateColor">
            {{ row.TypeStateName }}
          </v-chip>
        </template>

        <template v-slot:TypeAlert="{ row }">
          <v-chip x-small :color="row.TypeAlertColor">
            {{ row.TypeAlertName }}
          </v-chip>
        </template>

        <template v-slot:CloseLot="{ row }">
          <v-btn
            v-if="row.RcfState < 4 && row.Sampling == 1"
            x-small
            :color="row.TypeStateColor"
            rounded
            style="border-radius: 10px; text-transform: none"
            @click="closeLot(row)"
            >{{ "Cerrar" }}
            <!-- <v-icon style="font-size: 16px !important">fas fa-eye</v-icon> -->
          </v-btn>
        </template>

        
        <template v-slot:WeightGuideVB="{ row }">
          <v-switch 
            disabled
            style="margin-top: -1px"
            hide-details 
            v-model="row.RcfWeightGuide > 0 ? true : false"
            hide-spin-buttons
          > </v-switch>
          <!-- <v-chip x-small :color="row.RcfWeightGuide > 0 ? 'success': 'error'">
            {{row.RcfWeightGuide > 0 ? 'Si': 'No'}}
            
          </v-chip> -->
        </template>

         <template v-slot:RcfID="{ row }">
           
            {{ row.RcfID }} - {{row.RcfCode}}
           
        </template>

        <template v-slot:DeclineLot="{ row }">
            
             <v-btn
                v-if="row.RcfState <= 3 && $fun.hasSpecialPermission('rejectLot')"
                x-small
                :color="row.TypeStateColor"
                rounded
                style="border-radius: 10px; text-transform: none"
                @click="DeclineLot(row)"
                >{{ "Rechazar"  }}
                <!-- <v-icon style="font-size: 16px !important">fas fa-eye</v-icon> -->
            </v-btn>
           <v-btn
                v-if="row.RcfState == 12  && $fun.hasSpecialPermission('rejectLot')"
                x-small
                :color="row.TypeStateColor"
                rounded
                style="border-radius: 10px; text-transform: none"
                @click="ReceptionLot(row)"
                >{{ "Recepcionar" }}
                <!-- <v-icon style="font-size: 16px !important">fas fa-eye</v-icon> -->
            </v-btn>
        </template>
      </s-crud>
    </div>

    <v-dialog
      v-if="openDialogView"
      v-model="openDialogView"
      persistent
      style="display: contents"
      width="70%"
    >
      <s-certification-lot :RcfID="RcfIDCert" @close="closeCert()" />
    </v-dialog>

    <!-- ventana modal de pesos  -->
    <v-dialog
      v-model="dialogWeigh"
      v-if="dialogWeigh"
      fullscreen
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
    >
      <!-- <ApprovalLevelsDocument :DocEntry="DocEntry" :TypeObject="2" /> -->
      <v-card class="m-0 p-0">
        <s-toolbar
          label="Control de Pesado"
          dark
          :color="'#BAB6B5'"
          close
          @close="closeWeighed()"
          class="mb-6"
        ></s-toolbar>
        <weighed-by-batch :ship="ship" v-if="ship == 1"></weighed-by-batch>
        <!-- <frz-weighed-by-batch
          :ship="ship"
          v-if="ship == 2"
        ></frz-weighed-by-batch> -->
      </v-card>
    </v-dialog>
    <!-- modal del muestreo  v-->

    <v-dialog
      v-model="BrixGrade"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      persistent
      v-if="BrixGrade"
    >
      <v-card v-if="ship == 1" class="elevation-0">
        <BrixFresh :items="itemsampling" @close="closeBrixGrade()"></BrixFresh>
      </v-card>
      <!-- <v-card v-else class="elevation-0">
        <FrozenBrix
          :TypeShip="ship"
          :items="itemsampling"
          @close="BrixGrade = false"
        >
        </FrozenBrix>
      </v-card> -->
    </v-dialog>

    <v-dialog v-model="processing" :persistent="messageProcessing.length == 0" width="400">
				<v-card color="primary" dark>
					<v-card-text v-if="messageProcessing.length == 0">
					Por favor espere
					<v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
					</v-card-text>
					<v-card-text v-else>
					<div class="text--white pt-4">
						Error al realizar operacion <br />
						{{ messageProcessing }}
					</div>
					</v-card-text>
				</v-card>
			</v-dialog>

    <!-- Para merma -->
    <!-- <v-dialog
			width="700px"
			persistent
			v-model="dialogDecrease"
		>
			<decrease 	@closeDialogDecrease="closeDialogDecrease"
			@saveDecrease="saveDecrease"
			v-model="decrease"
			></decrease>
			
		</v-dialog> -->
  </v-container>
</template>

<script>
//Service
import _sReceptionFresh from "@/services/FreshProduction/ReceptionFreshService";
import _sGenParam from "../../../services/General/ParameterService";
import _sTurnService from "../../../services/FreshProduction/PrfTurnService";

import _sReportService from "@/services/FreshProduction/Report/ReceptionFreshReportService.js";
//Component
import SSearchLotByCodSenasa from "@/components/HarvestSchedule/sSearchLotByCodSenasa.vue";
import SSelectLot from "@/components/HarvestSchedule/SSelectLot.vue";
import Decrease from "@/components/FrozenProduction/Reception/decrease.vue";
import SSelectTransport from "@/components/HarvestSchedule/SSelectTransport";
import SSelectVariety from "@/components/HarvestSchedule/SSelectVariety";
import SSelectForeman from "@/components/HarvestSchedule/SSelectForeman";
import SSelectDriver from "@/components/HarvestSchedule/sSelectDriver.vue";
import AlertSupplies from "@/components/FreshProduction/FreshReception/AlertSupplies.vue";
import WeighedByBatch from "@/views/FreshProduction/RawMaterialReception/WeighedByBatch";
import FrzWeighedByBatch from "@/views/FrozenProduction/FrzWeighedByBatch/FrzWeighedByBatch";
import SCertificationLot from "@/components/TecnicalAssistence/sCertificationLot.vue";
import BrixFresh from "@/views/FreshProduction/RawMaterialReception/BrixFresh";

import FrozenBrix from "@/views/FrozenProduction/Reception/FrozenBrix";

import sSelectTypeCertification from "@/components/Utils/Certification/sSelectTypeCertification.vue";

import Unload from "./Unload.vue";
import sReceptionFreshUnloadService from '@/services/FreshProduction/ReceptionFreshUnloadService.js'

export default {
  props: {
    title: {
      type: String,
      default: "Recepción Frescos",
    },
    ship: {
      type: Number,
      default: 1,
    },
  },

  components: {
    Unload,
    FrozenBrix,
    Decrease,
    SSearchLotByCodSenasa,
    SSelectLot,
    SSelectTransport,
    SSelectVariety,
    SSelectForeman,
    SSelectDriver,
    AlertSupplies,
    WeighedByBatch,
    FrzWeighedByBatch,
    SCertificationLot,
    BrixFresh,
    sSelectTypeCertification,
  },

  data() {
    return {
      itemSelected: [],
      dialogTypeFile: false,
      paramDownload: 0,
      dialogUnload: false,
      dialogTurn: false,
      configTurn: {
        model: {
          TrnID: "ID",
          Actions: "",
          TrnStatus: "",
          TnrDateBegin: "datetime",
          TnrDateEnd: "datetime",
        },
        service: _sTurnService,
        headers: [
          { text: "Id", value: "TrnID", sortable: false, width: "15" },
          {
            text: "Acciones",
            value: "TrnStatus",
            sortable: false,
            width: 70,
          },
          { text: "Turno", value: "TypeTurnName", sortable: false },
          { text: "Inicio", value: "TnrDateBegin", sortable: false },
          { text: "Fin", value: "TnrDateEnd", sortable: false },
          {
            text: "Area",
            value: "TypeAreName",
            sortable: false,
            width: 200,
          },
        ],
      },
      dialogDecrease: false,
      decrease: {},
      FltID: 0,
      RcfIDCert: 0,
      disabledAll: false,
      selectedGang: [],
      selectedVehicle: [],
      itemsGang: [],
      itemsVehicle: [],
      //listSupplies: [],
      dialogWeigh: false,
      BrixGrade: false,

      itemsampling: {},

      headersGang: [
        { text: "Sub Lote", value: "FltName", width: 300 },
        { text: "Capataz", value: "FrmName", width: 350 },
        {
          text: "Cant. Personal.",
          value: "FrmQuantityHarvester",
          width: 100,
        },
        {
          text: "Cant. Prog.",
          value: "FrmQuantityEstimate",
          width: 100,
        },
        {
          text: "Cant. Cosechadas",
          value: "FrmQuantityReal",
          width: 100,
        },
        {
          text: "Tipo Carga",
          value: "TypeChargeName",
          width: 100,
        },
      ],

      headersVehicle: [
        { text: "Placa", value: "TptEnrollment", width: 150 },
        { text: "Chofer", value: "TptDriver", width: 200 },
        { text: "Tipo de Carga", value: "TypeLoadName", width: 100 },
      ],

      filter: {
        cDateInitial: null,
        cDateFin: null,
        /* cTypeServiceID: 1,
         */
        filterAll: 0,
        TypeDestiny: this.ship,
        TypeState: null,
      },

      config: {
        service: _sReceptionFresh,
        model: {
          RcfID: "ID",
          RcfDateReceptionSurveillance: "date",
          RcfState: "",
          CloseLot: "",
          TypeAlert: "",
          ViewCert: "",
          TypeAlertName: "",
          RcsDateHarvestSchedule: "date",
          WeightGuideVB: "",
          DeclineLot: ""
        },
        headers: [
          { text: "View", value: "ViewCert", width: 70 },
         
          { text: "Cerrar", value: "CloseLot", width: 50 },
          { text: "Estado", value: "RcfState", width: 20 },
          { text: "Codigo. Lote.", value: "RcfID", width: 100 },
          /*{ text: "Correl", value: "RcfCode", width: 100 },*/
          { text: "Verif.", value: "WeightGuideVB", width: 50 },
          
          {
            text: "Fec. Cosecha.",
            value: "RcsDateHarvestSchedule",
            width: 140,
          },
          {
            text: "Cant. Jabas Pesadas.",
            value: "AmountJabasWeigh",
            width: 160,
          },
          
            {
            text: "Guia Cosecha",
            value: "RcfNumberHarvestGuide",
            width: 160,
          }, 
         /* {
            text: "Peso Total Lote",
            value: "WbbNetWeight",
            width: 160,
          },
          {
            text: "Peso Guia",
            value: "RcfWeightGuide",
            width: 160,
          },
          {
            text: "Tipo Servicio",
            value: "TypeServiceName",
            width: 100,
          },*/
          {
            text: "Guia Remision",
            value: "RcfNumberReferralGuide",
            width: 160,
          },
          { text: "Productor", value: "PrdCardName", width: 260 },
          /*{ text: "Productor Reponsable Cobrar", value: "PrdCardNameResponsable", width: 260 },*/
          { text: "Fundo", value: "FagName", width: 260 },
         /* { text: "Lote", value: "FltName", width: 100 },*/
          { text: "Tipo Cultivo", value: "TypeCropName", width: 160 },
          { text: "Cultivo", value: "TypeCultiveName", width: 160 },
          { text: "Variedad", value: "VrtName", width: 160 },
          { text: "Cod. Senasa", value: "FltCodSenasa", width: 160 },
          {
            text: "Fec. Recep. Vigilancia.",
            value: "RcfDateReceptionSurveillance",
            width: 140,
          },

         
          
         /* {
            text: "Peso Muestreado",
            value: "WbbGrossWeight",
            width: 160,
          },*/
          {
            text: "Peso Promedio Fruta",
            value: "WbbWeightAverage",
            width: 160,
          },
          

       /*   {
            text: "Planta Procesdora",
            value: "TypeProcessingPlantName",
            width: 160,
          },*/
          { text: "Empacadora", value: "TypeBalerName", width: 160 },
          {
            text: "Zona Ubicacion",
            value: "TypeZonLocationName",
            width: 160,
          },
          { text: "Alerta", value: "TypeAlert", width: 100 },
          {
            text: "Observaciones",
            value: "RcfObservations",
            width: 260,
          },
           { text: "Rechazar", value: "DeclineLot", width: 50 },
        ],
      },

      selected: {},

      openDialogView: false,
      existsData: false,

      objLot: { PrdText: null, FagName: null, FltName: null },

      maskFormatGuideHarvested: "",
      maskFormatGuideRemission: "",
      xCount: 0,
      existUnload: false,

      processing:false,
    	error: false,
    	messageProcessing: "",
    };
  },

  created() {
    this.loadParameter();
  },

  methods: {
    syncLot(){
      console.log(this.selected);

      if (this.selected.RcfID == null) {
        return;
      }

      _sReceptionFresh.syncLot(this.selected, this.$fun.getUserID())
      .then( r => {
            console.log("r sync", r);
            if (r.status == 200) {
              this.$fun.alert("Se sincronizo correctamente", "success");
            }

      });

    },
    verififyDownloadRcf()
    {
     /*  if(this.itemsampling.RcfID != undefined){
        sReceptionFreshUnloadService
        .searchbyrcfid(this.itemsampling.RcfID, this.$fun.getUserID())
        .then(resp => {
          if(resp.status == 200){
            if(resp.data && resp.data.RftDateEnd != null){
              this.existUnload = true;
              console.log('ddddddddddd ', resp.data);
            }else{
              this.existUnload = false;
            }
          }
        })
      } */
      
    },

    openModalfilesGeneral(param) {
      this.paramDownload = param;
      this.dialogTypeFile = true;
    },

    filesGeneralDownloadExcel() {
      if (this.paramDownload == 1) {
        this.reportBRIX();
      } else {
        this.reportINTAKE();
      }
    },

    filesGeneralDownloadPdf() {
      if (this.paramDownload == 1) {
        this.reportBrixPDF();
      } else {
        this.reportIntakePDF();
      }
    },

    viewModalUnload() {
      this.dialogUnload = true;
    },

    closeModalUnload() {
      this.dialogUnload = false;
    },

/*     reportBRIX() {
      _sReportService
        .BRIX({ TrnID: 1, TnrBeginDate: "23/02/2022" })
        .then((r) => {
          this.$fun.downloadFile(r.data, this.$const.TypeFile.EXCEL, "BRIX");
        });
    },

    reportBrixPDF() {
      let obj = { TrnID: 1 };
      _sReportService.brixRPdf(obj, this.$fun.getUserID()).then((r) => {
        this.$fun.downloadFile(
          r.data,
          this.$const.TypeFile.PDF,
          "Control indicencia madurez de la fruta"
        );
      });
    },

    reportINTAKE() {
      _sReportService
        .INTAKE({ TrnID: 1, TnrBeginDate: "23/02/2022" })
        .then((r) => {
          this.$fun.downloadFile(r.data, this.$const.TypeFile.EXCEL, "INTAKE");
        });
    },

    reportIntakePDF() {
      let obj = { TrnID: 1 };
      _sReportService.IntakeRPdf(obj, this.$fun.getUserID()).then((r) => {
        this.$fun.downloadFile(
          r.data,
          this.$const.TypeFile.PDF,
          "Inspección en reception sobre la calidad de la materia prima"
        );
      });
    },

    reportEntryMaterial() {
      _sReportService
        .entryMaterial({ TrnID: 1, TnrBeginDate: "23/02/2022" })
        .then((r) => {
          this.$fun.downloadFile(
            r.data,
            this.$const.TypeFile.EXCEL,
            "Entrada de Materia Prima"
          );
        });
    }, */

    loadParameter() {
      _sGenParam
        .search({ PrmName: "maskFormatGuideHarvested" }, this.$fun.getUserID())
        .then((resp) => {
          if (resp.status == 200) {
            this.maskFormatGuideHarvested = resp.data.PrmValue;
          }
        });

      _sGenParam
        .search({ PrmName: "maskFormatGuideRemission" }, this.$fun.getUserID())
        .then((resp) => {
          if (resp.status == 200) {
            this.maskFormatGuideRemission = resp.data.PrmValue;
          }
        });
    },

    closeBrixGrade() {
      this.BrixGrade = false;
      this.$refs.gridReceptionFresh.refresh();
    },

    closeDialogDecrease() {
      this.dialogDecrease = false;
    },

    saveDecrease(item) {
      this.decrease = item;
    },

    rowClickGang: function (item, row) {
      this.selectedGang = [item];
    },

    rowClickVehicle: function (item, row) {
      this.selectedVehicle = [item];
    },

    clickView(val) {
      this.FltID = val.FltID;
      this.RcfIDCert = val.RcfID;
      this.openDialogView = true;
    },

    closeCert() {
      this.openDialogView = false;
    },

    closeWeighed() {
      this.dialogWeigh = false;
      this.$refs.gridReceptionFresh.refresh();
    },

    WeighMaterial() {
      this.dialogWeigh = true;
    },

    ModalBrix() {
      this.BrixGrade = true;
    },

    rowSelected(item) {
      if (item.length == 0) {
        this.selected = {};
        this.objLot = {};
        this.itemsGang = [];
        this.itemsVehicle = [];
        this.disabledAll = false;
        this.existsData = false;

        //this.hasSuppliesNotRecommended = false;
      } else {
        if (item[0] != undefined) {
          this.selected = item[0];

          this.existsData = true;

          _sReceptionFresh
            .GetGang({ RcfID: this.selected.RcfID }, this.$fun.getUserID())
            .then((r) => {
              this.itemsGang = r.data;
              console.log("this.itemsGang", this.itemsGang);
            });

          _sReceptionFresh
            .GetTransport({ RcfID: this.selected.RcfID }, this.$fun.getUserID())
            .then((r) => {
              this.itemsVehicle = r.data;
            });

          this.objLot.PrdText = this.selected.PrdCardName;
          this.objLot.FagName = this.selected.FagName;
          this.objLot.FltName = this.selected.FltName;

          this.objLot.PrdID = this.selected.PrdID;
          this.objLot.FagID = this.selected.FagID;
          this.objLot.FltID = this.selected.FltID;
          this.objLot.FltCodSenasa = this.selected.FltCodSenasa;
          this.objLot.TypeCrop = this.selected.TypeCrop;
          this.objLot.TypeCultive = this.selected.TypeCultive;
          this.objLot.VrtID = this.selected.VrtID;

          this.objLot.FagAddress = this.selected.FagAddres;
          this.objLot.ZonIDText = this.selected.ZonName;
          this.objLot.SzoIDText = this.selected.SzoName;

          if (this.selected.RcfState >= 4) {
            this.disabledAll = true;
          } else {
            this.disabledAll = false;
          }
        }
      }

      this.itemSelected = this.selected;
      this.itemSelected.itemsVehicle = this.itemsVehicle;

      this.itemsampling = this.selected;
      this.verififyDownloadRcf();
      console.log("print ", this.selected);
    },

    isValidatedForeman() {
      let isValidated = true;
      //Validar Grilla Capataces
      if (this.itemsGang.length == 0) {
        this.$fun.alert(
          "La Recepcion: " +
            this.selected.RcfID +
            ", No cuenta con Capataces asignados.",
          "warning"
        );
        isValidated = false;
        return;
      }

      this.itemsGang.forEach((element) => {
        console.log("element", element);

        if (element.FrmID == null) {
          this.$fun.alert(
            "Uno de los registro no se ha cargado Capataz",
            "warning"
          );
          isValidated = false;
          return;
        }

        if (element.FltID == null) {
          this.$fun.alert(
            "Uno de los registro no se ha cargado Sub-Lote",
            "warning"
          );
          isValidated = false;
          return;
        }

        if (element.FrmQuantityHarvester <= 0) {
          this.$fun.alert(
            "Cantidad de Cosechadores no puede ser menor o igual a 0, para Capataz: " +
              element.FrmName,
            "warning"
          );
          isValidated = false;
          return;
        }

        if (element.FrmQuantityReal <= 0) {
          this.$fun.alert(
            "Cantidad de Fruta no puede ser menor o igual a 0, para Capataz: " +
              element.FrmName,
            "warning"
          );
          isValidated = false;
          return;
        }
      });

      return isValidated;
    },

    isValidatedVehicle() {
      let isValidated = true;

      if (this.selected.FrzID == 0) {
        //Validar Grilla Vehiculos

        if (this.itemsVehicle.length == 0) {
          this.$fun.alert(
            "La Recepcion: " +
              this.selected.RcfID +
              ", No cuenta con Vehiculos asignados.",
            "warning"
          );
          isValidated = false;
          return;
        }

        this.itemsVehicle.forEach((element) => {
          if (element.TptEnrollment == null) {
            this.$fun.alert("Necesita especificar un Vehiculo", "warning");
            isValidated = false;
            return;
          }

          if (element.TptDriver == null) {
            this.$fun.alert("Necesita especificar un Chofer", "warning");
            isValidated = false;
            return;
          }

          if (element.TypeLoad == null) {
            this.$fun.alert("Necesita especificar tipo de carga", "warning");
            isValidated = false;
            return;
          }
        });
      }
      return isValidated;
    },

    isValidatedDocuments(val) {
      let isValidated = true;
      if (
        val.RcfNumberHarvestGuide == "" ||
        val.RcfNumberHarvestGuide == null
      ) {
        this.$fun.alert("Ingrese Numero Guia de Cosecha", "warning");
        isValidated = false;
        return;
      }

      if (this.selected.FrzID == 0) {
        if (
          val.RcfNumberReferralGuide == "" ||
          val.RcfNumberReferralGuide == null
        ) {
          this.$fun.alert("Ingrese numero Guia de Remision", "warning");
          isValidated = false;
          return;
        }

        if (
          val.RcfNumberFerralGuideDriver == "" ||
          val.RcfNumberFerralGuideDriver == null
        ) {
          this.$fun.alert(
            "Ingrese numero Guia remision de Transportista",
            "warning"
          );
          isValidated = false;
          return;
        }
      }

      return isValidated;
    },

    isValidatedHeader(val) {
      let isValidated = true;

      if (val.RcfID == 0) {
        this.$fun.alert("No se ha seleccionado registro", "warning");
        isValidated = false;
        return;
      }

      if (this.objLot.PrdText == null || this.objLot.PrdText == undefined) {
        this.$fun.alert("No se ha seleccionado Productor", "warning");
        isValidated = false;
        return;
      }

      if (this.objLot.FagName == null || this.objLot.FagName == undefined) {
        this.$fun.alert("No se ha seleccionado Fundo", "warning");
        isValidated = false;
        return;
      }

      if (this.objLot.FltName == null || this.objLot.FltName == undefined) {
        this.$fun.alert("No se ha seleccionado Lote", "warning");
        isValidated = false;
        return;
      }

      if (
        this.objLot.TypeCultive == undefined ||
        this.objLot.TypeCultive == null
      ) {
        this.$fun.alert("No se ha seleccionado Cultivo", "warning");
        isValidated = false;
        return;
      }

      if (this.objLot.TypeCrop == undefined || this.objLot.TypeCrop == null) {
        this.$fun.alert("No se ha seleccionado Tipo Cultivo", "warning");
        isValidated = false;
        return;
      }

      return isValidated;
    },
    ReceptionLot(items) {
      //console.log("cerrar lote", items);
        
      this.$fun
        .alert("Esta seguro de Recepcionar el Lote ? ", "question")
        .then((val) => {
          if (val.value) {
            items.itemsVehicle = null
            items.RcfState = 50;
            items.UsrCreateID = this.$fun.getUserID();

            console.log("cerrar lote", items);

            _sReceptionFresh.save(items, this.$fun.getUserID()).then((r) => {
              if (r.status == 200) {
                this.$fun.alert("Lote Recepcionado correctamente", "success");
                this.$refs.gridReceptionFresh.refresh();
              }
            });
            this.$refs.gridReceptionFresh.refresh();
          }
        });
    },
    DeclineLot(items) {
      //console.log("cerrar lote", items);
        
      this.$fun
        .alert("Esta seguro de Rechazar el Lote ? ", "question")
        .then((val) => {
          if (val.value) {
            items.itemsVehicle = null
            items.RcfState = 40;
            items.UsrCreateID = this.$fun.getUserID();

            console.log("cerrar lote", items);

            _sReceptionFresh.save(items, this.$fun.getUserID()).then((r) => {
              if (r.status == 200) {
                this.$fun.alert("Lote Rechazado correctamente", "success");
                this.$refs.gridReceptionFresh.refresh();
              }
            });
            this.$refs.gridReceptionFresh.refresh();
          }
        });
    },
    closeLot(items) {
      //console.log("cerrar lote", items);
      if (items.RcfState >= 4) {
        this.$fun.alert(
          "Lote se encuentra ==> " + items.TypeStateName,
          "warning"
        );
        return;
      }

      this.$fun
        .alert("Esta seguro de Cerrar el Lote ? ", "question")
        .then((val) => {
          if (val.value) {
            items.itemsVehicle = null
            items.RcfState = 4;
            items.UsrCreateID = this.$fun.getUserID();

            console.log("cerrar lote", items);

            _sReceptionFresh.save(items, this.$fun.getUserID()).then((r) => {
              if (r.status == 200) {
                this.$fun.alert("Lote cerrado correctamente", "success");
                this.$refs.gridReceptionFresh.refresh();
              }
            });
            this.$refs.gridReceptionFresh.refresh();
          }
        });
    },

    save(val) {
      if (val.RcfState >= 4) {
        this.$fun.alert(
          "Lote se encuentra ==> " +
            val.TypeStateName +
            ", No se puede modifcar.",
          "warning"
        );
        return;
      }

      if (!this.isValidatedHeader(val)) {
        return;
      }
      if (!this.isValidatedForeman()) {
        return;
      }
      if (!this.isValidatedVehicle()) {
        return;
      }
      if (!this.isValidatedDocuments(val)) {
        return;
      }

      this.xCount = 0;
      val.SecStatus = 1;
      val.UsrCreateID = this.$fun.getUserID(); //Obtenemos el ID del Usuario
      //val.RcfState = 1;
      val.RcfDateReception = this.$fun.getDate();

      val.PrdID = this.objLot.PrdID;
      val.FagID = this.objLot.FagID;
      val.FltID = this.objLot.FltID;

      val.TypeCrop = this.objLot.TypeCrop;
      val.TypeCultive = this.objLot.TypeCultive;
      val.VrtID = this.objLot.VrtID;

      //Verificamos que Planta Procesadora es Sunshine, Establecemos Empacadora en NULL
      if (val.TypeProcessingPlant == 1) {
        val.TypeBaler = null;
      }

      //Agregamos el RcsID a Capataces
      this.itemsGang.forEach((element) => {
        element.RcfID = val.RcfID;
        element.SecStatus = 1;
        element.UsrCreateID = this.$fun.getUserID();
      });

      //Agregamos el RcsID a Vehiculos
      this.itemsVehicle.forEach((element) => {
        element.RcfID = val.RcfID;
        element.SecStatus = 1;
        element.UsrCreateID = this.$fun.getUserID();

        if (element.TypeLoad == 1) {
          this.xCount = this.xCount + 1;
        }
      });

      val.itemsGang = this.itemsGang;
      val.itemsVehicle = this.itemsVehicle;

      if (this.selected.FrzID == 0) {
        if (this.xCount > 1) {
          this.$fun.alert(
            "Error... El Lote de Producción solo puede tener 01 vehiculo para transporte de Fruta",
            "warning"
          );
          return;
        }

        if (this.xCount == 0) {
          this.$fun.alert(
            "Error... El Lote de Producción no contiene vehiculo para transporte de Fruta",
            "warning"
          );
          return;
        }
      }

      console.log("Guardar", val);

      this.$fun.alert("Seguro de Guardar?", "question")
				.then(r => {
					if(r.value){
						this.processing = true;
						_sReceptionFresh
						.save(val, this.$fun.getUserID())
							.then(resp => {
								if (resp.status == 200) {
									this.processing = false;
									this.$fun.alert("Guardado Correctamente", "success");
                  this.$refs.gridReceptionFresh.refresh()
								}
							},
							(e) => {
								this.error = true;
								this.messageProcessing = e.response.data.Message;
								this.processing = true;
							}
						
						);
					}
				})

      //val.save();
    },

    openAudio() {
      var audio = new Audio(require("../../../../../sound/nohaynadie.mp3"));
      audio.play();
    },

    searchSenasa(item) {
      if (item) this.objLot = item;
      else this.objLot = {};

      console.log("this.objLot", this.objLot);
    },

    editEvent() {
      if (this.existsData == false) {
        this.$fun.alert("No se ha seleccionado registro", "warning");
      }
    },

    enableLP() {
      this.$fun
        .alert("¿ Esta seguro de Habilitar Lote Producción ?", "question")
        .then((x) => {
          if (x.value) {
            this.selected.RcfState = 20;
            this.selected.UsrCreateID = this.$fun.getUserID();

            _sReceptionFresh
              .save(this.selected, this.$fun.getUserID())
              .then((resp) => {
                if (resp.status == 200) {
                  this.$fun.alert("LP Habilitado", "success");
                  this.$refs.gridReceptionFresh.refresh();
                }
              });
          }
        });
    },
  },
};
</script>
          <v-dialog v-model="dialogTurn" v-if="dialogTurn" width="700">
            <v-col>
              <v-card>
                <v-col cols="12" lg="12" md="12" class="pb-0">
                  <s-crud
                    :config="configTurn"
                    :filter="{ TypeAre: 1, TypeAre: 1 }"
                    no-search
                    title="Reportes por Turnos"
                    ref="gridTurn"
                    no-full
                    height="auto"
                  >
                    <template v-slot:options>
                      <v-btn
                        class="mr-1"
                        color="warning"
                        x-small
                        @click="openModalfilesGeneral(1)"
                      >
                        BRIX</v-btn
                      >
                      <v-btn
                        class="mr-1"
                        color="success"
                        x-small
                        @click="openModalfilesGeneral(2)"
                      >
                        INTAKE</v-btn
                      >
                      <v-btn
                        class="mr-1"
                        color="primary"
                        x-small
                        @click="dialogTurn = true"
                      >
                        CAMIONES</v-btn
                      >
                      <v-btn
                        color="primary"
                        x-small
                        @click="reportEntryMaterial()"
                      >
                        INGRESO MATERIAL</v-btn
                      >
                    </template>

                    <template v-slot:TrnStatus="{ row }">
                      <v-btn
                        x-small
                        :color="row.TrnStatus == 1 ? 'error' : 'default'"
                        :height="25"
                        rounded
                        style="margin: 10px 10px 10px 5px"
                        ><i
                          style="font-size: 16px; margin-right: 6px"
                          class="far fa-stop-circle"
                        ></i>
                        {{ row.TrnStatus == 1 ? "Turno Activo " : "Terminado" }}
                      </v-btn>
                    </template>
                  </s-crud>
                </v-col>
              </v-card>
              <!-- ventana de si es archivo excel o pdf -->

<!--               <v-dialog
                v-if="dialogTypeFile"
                v-model="dialogTypeFile"
                width="300"
              >
                <v-card>
                  <v-container>
                    <v-row justify="center">
                      <v-col>
                        <v-btn
                          color="green"
                          dark
                          @click="filesGeneralDownloadExcel()"
                          ><v-icon style="padding-right: 10px"
                            >fa-solid fa-file-excel</v-icon
                          >Excel</v-btn
                        >
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col>
                        <v-btn
                          color="red"
                          dark
                          @click="filesGeneralDownloadPdf()"
                        >
                          <v-icon style="padding-right: 10px"
                            >fa-solid fa-file-pdf</v-icon
                          >
                          Pdf</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-dialog>
            </v-col>
          </v-dialog> -->


                  <template v-slot:options>
          <v-btn
            class="mr-1"
            color="success"
            x-small
            @click="dialogTurn = true"
          >
            Turnos</v-btn
          >
          <!-- <v-btn 
            color="primary" 
            x-small 
            @click="viewModalUnload()"
            :disabled="existUnload"
          >
            Descarga Fruta</v-btn
          >
          <v-dialog v-model="dialogUnload" v-if="dialogUnload" width="700">
            <unload
              @closeModalUnload="closeModalUnload()"
              :item="itemSelected"
            ></unload>
          </v-dialog> -->




        </template>