<template>
  <v-card>
    <v-container>
      <v-row justify="center">
          <!--TptEnrollment-->
        <v-col cols="12" lg="4" md="6">
          <s-select
            :items="itemsNumberGuide"
            item-value="TptEnrollment"
            full
            autocomplete
            item-text="TptEnrollment"
            label="Seleccione Nro. Placa"
            :slotTmp="true"
            return-object
            v-model="objGuide"
            clearable
            @input="inputGuide($event)"
          >
            <template v-slot:item="data">
              
              {{ data.item.TptEnrollment }} , {{data.item.RcfID}} - {{data.item.RcfCode}}
              <v-chip
                x-small
                style="margin-left: 5px"
                :color="
                  data.item.RftDateBegin !== null &&
                  data.item.RftDateEnd == null
                    ? 'warning'
                    : data.item.RftDateEnd !== null
                    ? 'info'
                    : 'error'"
                >{{
                  data.item.RftDateBegin !== null &&
                  data.item.RftDateEnd == null
                    ? "Descarga Iniciada"
                    : data.item.RftDateEnd !== null
                    ? "Descarga finalizada"
                    : "Sin Descargar"
                }}</v-chip
              >
            </template>
          </s-select>
        </v-col>

        <v-col cols="12" lg="4" md="6">
          <s-select-activity-area
            label="Cuadrilla"
            :typeShip="itemul.TypeChip"
            :typeArea="1"
            v-model="AtaID"
          ></s-select-activity-area>
        </v-col>

        <v-col cols="12" lg="4" md="6">
          <s-select-definition
            label="Muñeco"
            :def="1388"
            v-model="TypeDoll"
          ></s-select-definition>
        </v-col>

        <v-col cols="12" lg="2" md="6">
          <v-btn color="warning" block class="mt-2" @click="closeModalUnload()"
            >Cancelar</v-btn>
        </v-col>
        <v-col
          cols="12"
          lg="2"
          md="6"
          v-if="RftDateBegin !== null && RftDateEnd == null">
          <v-btn color="error" block class="mt-2" @click="save(2)"
            >Finalizar Descarga</v-btn>
        </v-col>
        <v-col
          cols="12"
          lg="2"
          md="6"
          v-if="RftDateBegin == null && RftDateEnd == null">
          <v-btn 
							class="mt-2"
      				dark
							block
      				color="indigo"
      			 @click="save(1)">
						iniciar descarga</v-btn>
        </v-col>
      </v-row>
			<v-row style="padding-left:10px; padding-top:20px">
				<h3>Descargas</h3>
			</v-row>
      <v-row>
        <v-divider></v-divider>
      </v-row>
     <!--  <v-row justify="center">
				<v-col cols="12">
					<v-data-table
						:headers="headerDownload"
						:items="itemsDataDownloaded">

						<template v-slot:item.RftDateBegin="{ item }">
							 {{ 
							 		(item.RftDateBegin) ? moment(item.RftDateBegin).format('DD-MM-YYYY')  : '' 
							 }}
						</template>

						<template v-slot:item.RftDateEnd="{ item }">
							 {{ 
								 (item.RftDateEnd) ? moment(item.RftDateEnd).format('DD-MM-YYYY')  : '' 
								}}
						</template>

					</v-data-table>
			
				</v-col> 
			</v-row> -->
    </v-container>
  </v-card>
</template>

<script>
import sSelectActivityArea from "../../../components/FreshProduction/Turn/sSelectActivityArea.vue";
import sReceptionFreshUnloadService from "../../../services/FreshProduction/ReceptionFreshUnloadService.js";

export default {
  components: { sSelectActivityArea},
  props: {
    item: null,
    itemul: null
  },

  data() {
    return {
      TypeDoll: 0,
      itemsNumberGuide: [],
			itemsDataDownloaded: [],
      AtaID: 0,
			headerDownload : [
					{text: "Cod" , value : "RftID" },
					{text: "Cod Turno" , value : "TrnID" },
					{text: "Cantidad personas" , value : "RftQuantityPeople" },
					{ text : "Fecha inicio", value : "RftDateBegin"},
					{ text : "Fecha fin", value : "RftDateEnd"},				
			],
      objGuide: null,
      RftDateBegin: null,
      RftDateEnd: null,
      RcfID: 0,
      AtaIdBd: 0,
    };
  },

  methods: {
    loadData() {
      //Cargar las Guia  de remision
      sReceptionFreshUnloadService
        .getReferraltGuide({}, this.$fun.getUserID())
        .then((r) => {
					console.log('datos de las guias', r.data)
          this.itemsNumberGuide = r.data;
          console.log("HOLAAAAAAAAAAAA", this.itemsNumberGuide);
        });
    },

    inputGuide(item) {
      //console.log("selecciono una placa samir", item);

      if (item !== null) {
        console.log('type doll ', item);
        this.RcfID = item.RcfID;
        this.RftDateEnd = item.RftDateEnd;
        this.RftDateBegin = item.RftDateBegin;

        this.AtaID = item.AtaID;
        this.TypeDoll = item.TypeDoll;
        this.AtaIdBd = item.AtaID;
      }
    },
    /* Initialize() {
				sReceptionFreshUnloadService
					.searchbyrcfid(this.item.RcfID, this.$fun.getUserID())
					.then((res) => {
						if (res.status == 200) {
							console.log("resss ", res.data);
							//this.unload = res.data;
							if (res.data) {
								this.unload = res.data;
								this.unload.TptEnrollment = res.data.RftPlate;
								this.isEdit = true;
								console.log("a editar ", this.unload);
							} else {
								this.unload = this.item;
								this.unload.TptEnrollment =
									this.item.itemsVehicle[0].TptEnrollment;
								this.isEdit = false;
							}
						}
					});
			}, */

    closeModalUnload() {
      this.$emit("closeModalUnload");
    },

		showDataDownload(){
		/* 	let obj = {
				TrnID : 0,
			};
			sReceptionFreshUnloadService.pagination(obj, this.$fun.getUserID())
			.then((r) => {
				if(r.status == 200){
					console.log('resultado', r.data.data);
					this.itemsDataDownloaded = r.data.data;
				}
			}); */
		},

    save(xFlag) {
      if (this.objGuide == null) {
        this.$fun.alert("Seleccione Placa", "warning");
        return;
      }

      if (this.AtaID == 0 || this.AtaID == null) {
        this.$fun.alert("Seleccione Cuadrilla", "warning");
        return;
      }

      //verificar si es su cuadrilla samir

      console.log("el flag", xFlag);
      console.log("el ataIdBD", this.AtaIdBd);
      if(this.AtaIdBd > 0 && xFlag != 1){
        if(this.AtaIdBd != this.AtaID){
          this.$fun.alert("No es su Cuadrilla Asignada", "warning");
          return;
        }
      }  
      //veriificar si la cuadrilla tiene personas asignadas samir
     
      sReceptionFreshUnloadService.getNumberPersonByGang(this.AtaID,this.$fun.getUserID())
       .then(resp => {
          //console.log("NUMERO DE PERSONNAS ASIGNADAS A LA CUADRILLA", resp);
          if(resp.data == 0){
            this.$fun.alert("La Cuadrilla tiene 0 Personas Asignadas", "warning");
            return;
          }
       });

      let unlo = {};

      unlo.RftID = this.objGuide.RftID;
      unlo.RcfID = this.objGuide.RcfID;
      unlo.TptEnrollment = this.objGuide.TptEnrollment;
      
      unlo.AtaID = this.AtaID;
      unlo.SecStatus = 1;
      unlo.UsrCreateID = this.$fun.getUserID();

      unlo.TypeShip = this.itemul.TypeShip;
      unlo.TypeDoll = this.TypeDoll;

      console.log("GUARDAR", unlo);

      var message = "";
      var success = "";

      console.log("indica si modifica", xFlag);

      if (xFlag == 1) {
        message = "¿Seguro de iniciar descarga ?";
        success = "Descarga iniciada correctamente";

				this.$fun.alert(message, "question").then((val) => {
					if (val.value) {
						sReceptionFreshUnloadService
							.save(unlo, this.$fun.getUserID())
							.then((resp) => {
								if (resp.status == 200) {
									this.$fun.alert(success, "success");
									this.objGuide = null;
									this.loadData();

									//this.closeModalUnload()
								}
							});
							
					}
				});

      } else {
        message = "¿Seguro de finalizar descarga ?";
        success = "Descarga finalizada correctamente";

				 this.$fun.alert(message, "question").then((val) => {
        if (val.value) {
          sReceptionFreshUnloadService
            .save(unlo, this.$fun.getUserID())
            .then((resp) => {
              if (resp.status == 200) {
                this.$fun.alert(success, "success");
                this.objGuide = null;
                this.loadData();

                //this.closeModalUnload()
              }
            });

					this.showDataDownload();
						
        }
      });

			
      }

     

      /* let unlo;

				if (this.isEdit) {
					unlo = this.unload;
					unlo.RftQuantityBoxes = this.item.AmountJabasWeigh;
					unlo.UsrUpdateID = this.$fun.getUserID();

				} else {
					unlo = {
						RftID: 0,
						RftPlate: this.unload.TptEnrollment,
						RcfID: this.unload.RcfID,
						RftQuantityBoxes: this.item.AmountJabasWeigh, //
						AtaID: this.unload.AtaID, //
						TrnID: 0, //
						RftQuantityPeople: 0,
						RftDateEnd: new Date(),
						RftDateEnd: new Date(),
						TypeDoorFresh: 0,
						SecStatus: 1,
						UsrCreateID: this.$fun.getUserID(),
						UsrUpdateID: this.$fun.getUserID(),
					};
				} */

      /* console.log("send ", unlo);

				sReceptionFreshUnloadService
					.save(unlo, this.$fun.getUserID())
					.then((resp) => {
						if (resp.status == 200) {
							this.$fun.alert("Ingresado correctamente", "success");
							this.closeModalUnload();
						}
					}); */
    },
  },

  created() {
    this.AtaID = this.item;
    this.loadData();

    /* if (this.item.RcfID != undefined) {
				this.Initialize();
			} */

    /* console.log('item received ', this.unload, this.item.itemsVehicle[0].TptEnrollment); */     
  },
};
</script>