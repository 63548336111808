import Service from "../../Service";

const resource = "reportReceptionFresh/"

export default {
    BRIX(dtr, requestID) {

        return Service.post(resource + "brixR", dtr, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: {},
        });
    },

    brixRPdf(obj, requestID) {
        return Service.post(resource + "brixRPdf", obj, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: { requestID }
        });
    },

    IntakeRPdf(obj, requestID) {
        return Service.post(resource + "IntakeRPdf", obj, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: { requestID }
        });
    },


    INTAKE(dtr, requestID) {
        console.log(dtr);
        return Service.post(resource + "qualityMaterial", dtr, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: {},
        });
    },


    entryMaterial(dtr, requestID) {
        console.log(dtr);
        return Service.post(resource + "entryMaterial", dtr, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: {},
        });
    },
}